import '../css files/footer.css';


const Footer = (props) => {

    return (
        <div className="footer">
            <h4>Created by Fengji Zhang</h4>

            
        </div>
    )
}

export default Footer;